.calculator {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}

.title {
  font-size: 24px;
  text-align: left;
  flex-grow: 20;
  margin-left: 5%;
}

.calculator-content {
  flex-grow: 1;
  margin-left: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  width: 300px;
  margin-right: 4%;
}

.display {
  background-color: #505050;
  border: 1px solid #ccc;
  padding: 10px;
  text-align: right;
  font-size: 24px;
  color: white;
}

.buttons {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
